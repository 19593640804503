<template>
  <div class="bg-white">
    <section class="max-w-screen-xl px-6 py-20 mx-auto">
      <div class="w-full lg:w-1/2">
        <h2 class="mb-4 text-2xl font-bold text-atn-primary sm:text-3xl">
          {{ $t("why-telemedicine") }}?
        </h2>
      </div>
      <div class="flex flex-wrap overflow-hidden bg-white border rounded-md">
        <div class="flex-1 border-r">
          <div class="p-4 mb-4 bg-atn-primary">
            <h2 class="text-lg font-semibold text-white">
              {{ $t("patients") }}
            </h2>
          </div>
          <div class="px-6 py-3">
            <div v-for="list in telemedicine" :key="list.patients.id">
              <div
                class="flex mb-6 space-x-2"
                v-for="patient in list.patients"
                :key="patient.id"
              >
                <svg
                  class="flex-shrink-0 w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#1c6376"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m9 12 2 2 4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0z"
                  />
                </svg>
                <p>
                  {{ patient.pros }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-1">
          <div class="p-4 mb-4 bg-atn-primary">
            <h2 class="text-lg font-semibold text-white">
              {{ $t("physicians") }}
            </h2>
          </div>
          <div class="px-6 py-3">
            <div v-for="list in telemedicine" :key="list.physicians.id">
              <div
                class="flex mb-6 space-x-2"
                v-for="physician in list.physicians"
                :key="physician.id"
              >
                <svg
                  class="flex-shrink-0 w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#1c6376"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m9 12 2 2 4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0z"
                  />
                </svg>
                <p>
                  {{ physician.pros }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../Api";
import { mapGetters } from "vuex";
export default {
  name: "WhyTelemedicine",
  data() {
    return {
      telemedicine: [],
      error: null,
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiWhyTelemedicine",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    async getData() {
      try {
        const response = await axios.get(this.api);
        this.telemedicine = response.data;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style></style>
