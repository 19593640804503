<template>
  <div>
    <div class="max-w-screen-xl px-6 py-20 mx-auto lg:flex">
      <what-is-telemedicine class="lg:w-1/2" />
      <!-- <div class="h-48 max-w-screen-xl mx-auto bg-red-400 hero">

      </div> -->
      <img
        src="../assets/Telemedicine_resized.png"
        alt="telemedicine"
        class="lg:w-1/2"
      />
    </div>
    <section class="text-gray-700 max-w-sceen-xl">
      <why-telemedicine></why-telemedicine>
      <AboutUs></AboutUs>
      <Timeline></Timeline>
      <Partners></Partners>
      <Subscribe></Subscribe>
    </section>
  </div>
</template>

<script>
import WhatIsTelemedicine from "../components/home/WhatIsTelemedicine.vue";
import WhyTelemedicine from "../components/home/WhyTelemedicine.vue";
import AboutUs from "../components/home/AboutUs.vue";
import Timeline from "../components/home/Timeline.vue";
import Partners from "../components/home/Partners.vue";
import Subscribe from "../components/home/Subscribe.vue";

export default {
  name: "Home",
  components: {
    Partners,
    AboutUs,
    Timeline,
    Subscribe,
    WhyTelemedicine,
    WhatIsTelemedicine,
  },
};
</script>
<style scoped>
/* .hero {
  background-image: linear-gradient(
      to right bottom,
      rgba(93, 168, 161, 0.8),
      rgba(0, 10, 34, 0.8)
    ),
    url("../assets/Telemedicine.png");
  background-position: center;
} */
.hero {
  min-height: 500px;
}
</style>
