<template>
  <div>
    <div class="flex items-center justify-center h-72 hero">
      <div class="text-center text-gray-200">
        <h1 class="mb-4 text-4xl font-bold">ATN Resource Center</h1>
      </div>
    </div>
    <div class="max-w-6xl px-6 py-20 mx-auto">
      <!-- container for all cards -->
      <div class="flex flex-col">
        <!-- card -->
        <div
          v-for="resource in resources"
          :key="resource.id"
          class="flex flex-col mx-2 mt-6 overflow-hidden bg-white rounded-lg md:flex-row w-100"
        >
          <!-- media -->
          <div class="w-auto p-6 md:w-1/2 lg:w-1/3" v-if="resource.image">
            <img
              class="inset-0 object-cover object-top w-full h-full"
              :src="getBaseUrl() + resource.image.url"
            />
          </div>
          <!-- content -->
          <div class="flex flex-col w-full px-6 py-10 space-y-4">
            <a
              :href="resource.link"
              target="_blank"
              class="font-semibold leading-tight text-atn-primary hover:underline"
              >{{ resource.name }}</a
            >
            <!-- <h3 class="text-lg font-semibold leading-tight text-atn-primary">{{ resource.name }}</h3> -->
            <p class="mt-2 text-sm">
              {{ resource.publisher }}
            </p>
            <!-- <p class="mt-2 text-sm font-semibold tracking-wide text-gray-700 uppercase">
                     {{ card.author }} &bull; {{ card.date }}
                  </p> -->
            <a
              :href="resource.link"
              target="_blank"
              class="text-sm text-atn-primary hover:underline"
            >
              {{ resource.link }}
            </a>
          </div>
        </div>
        <!--/ card-->
      </div>
      <!--/ flex-->
    </div>
    <!-- <section class="max-w-6xl px-6 py-20 mx-auto">
         <h1 class="mb-4 text-4xl font-bold text-atn-primary">
            ETA Resource Center
         </h1>
         <div class="flex flex-col mb-8 space-y-2" v-for="resource in resources" :key="resource.id">
            <p class="text-lg font-semibold text-atn-secondary">
               {{ resource.name}}
            </p>
            <p class="text-sm">
               {{ resource.publisher }}
            </p>
            <a :href="resource.link" target="_blank" class="text-sm text-atn-primary hover:underline">
               {{ resource.link }}
            </a>
         </div>
      </section> -->
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";

export default {
  name: "Services",
  data() {
    return {
      resources: "",
    };
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
  },
  async mounted() {
    try {
      const resources = await axios.get(`${baseUrl}/resources`);
      this.resources = resources.data;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style scoped>
.hero {
  background-image: linear-gradient(
      to right bottom,
      rgba(93, 168, 161, 0.8),
      rgba(0, 10, 34, 0.8)
    ),
    url("../assets/3.png");
  background-position: center;
}
/* .clip {
   clip-path: polygon(0 0, 100% 0, 50% 100%, 0% 100%);
} */
</style>
