var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"max-w-3xl px-6 py-20 mx-auto text-gray-600"},[_c('div',{staticClass:"p-10 bg-white rounded shadow"},[_vm._m(0),_c('p',{staticClass:"mb-8 text-sm"},[_vm._v(" ATN brings together healthcare professionals, medical students and telemedicine enthusiasts to advance telemedicine in Africa. ATN provides unique learning and networking opportunities to its members through networking and professional development events. ")]),_c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"w-full p-2 mb-2 sm:w-1/2"},[_c('div',{staticClass:"relative"},[_c('label',{staticClass:"block mb-2 text-sm font-medium",attrs:{"for":"fName"}},[_vm._v(" "+_vm._s(_vm.$t("first-name"))+" ")]),_c('ValidationProvider',{attrs:{"name":"First name","rules":"required|alpha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fName),expression:"fName"}],staticClass:"w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none",attrs:{"type":"text","id":"fName","name":"firstName","placeholder":_vm.$t('first-name')},domProps:{"value":(_vm.fName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.fName=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"w-full p-2 mb-2 sm:w-1/2"},[_c('div',{staticClass:"relative"},[_c('label',{staticClass:"block mb-2 text-sm font-medium",attrs:{"for":"lName"}},[_vm._v(" "+_vm._s(_vm.$t("last-name"))+" ")]),_c('ValidationProvider',{attrs:{"name":"Last name","rules":"required|alpha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lName),expression:"lName"}],staticClass:"w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none",attrs:{"type":"text","id":"lName","name":"lastName","placeholder":_vm.$t('last-name')},domProps:{"value":(_vm.lName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.lName=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"w-full p-2 mb-2 sm:w-1/2"},[_c('div',{staticClass:"relative"},[_c('label',{staticClass:"block mb-2 text-sm font-medium",attrs:{"for":"title"}},[_vm._v(" "+_vm._s(_vm.$t("title"))+" ")]),_c('ValidationProvider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.title),expression:"title"}],staticClass:"w-full px-3 py-1 text-sm leading-8 text-gray-700 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none",attrs:{"name":"title","id":"title"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.title=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":""}},[_vm._v("Title")]),_c('option',{attrs:{"value":"Mr"}},[_vm._v("Mr")]),_c('option',{attrs:{"value":"Miss"}},[_vm._v("Miss")]),_c('option',{attrs:{"value":"Mrs"}},[_vm._v("Mrs")]),_c('option',{attrs:{"value":"Dr."}},[_vm._v("Dr.")]),_c('option',{attrs:{"value":"Prof"}},[_vm._v("Prof")]),_c('option',{attrs:{"value":"other"}},[_vm._v("other")])]),_c('span',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"w-full p-2 mb-2 sm:w-1/2"},[_c('div',{staticClass:"relative"},[_c('label',{staticClass:"block mb-2 text-sm font-medium",attrs:{"for":"title"}},[_vm._v(" "+_vm._s(_vm.$t("age"))+" ")]),_c('ValidationProvider',{attrs:{"name":"Age","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.age),expression:"age"}],staticClass:"w-full px-3 py-1 text-sm leading-8 text-gray-700 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none",attrs:{"name":"age","id":"age"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.age=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":""}},[_vm._v("Age")]),_c('option',{attrs:{"value":"Under 12 years"}},[_vm._v("Under 12 years")]),_c('option',{attrs:{"value":"12-17 years old"}},[_vm._v("12-17 years old")]),_c('option',{attrs:{"value":"18-24 years old"}},[_vm._v("18-24 years old")]),_c('option',{attrs:{"value":"25-34 years old"}},[_vm._v("25-34 years old")]),_c('option',{attrs:{"value":"35-44 years old"}},[_vm._v("35-44 years old")]),_c('option',{attrs:{"value":"45-54 years old"}},[_vm._v("45-54 years old")]),_c('option',{attrs:{"value":"55-64 years old"}},[_vm._v("55-64 years old")]),_c('option',{attrs:{"value":"65-74 years old"}},[_vm._v("65-74 years old")]),_c('option',{attrs:{"value":"75 years or older"}},[_vm._v(" 75 years or older ")])]),_c('span',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"flex w-full p-2 my-8 item-center bg-gray-50"},[_c('div',{staticClass:"p-4"},[_c('span',{staticClass:"block mb-2 text-sm font-medium"},[_vm._v(" "+_vm._s(_vm.$t("gender"))+" ")]),_c('div',{staticClass:"mt-2"},[_c('ValidationProvider',{attrs:{"name":"Type Of Appointment","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"inline-flex items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.gender),expression:"gender"}],staticClass:"w-4 h-4 form-radio",attrs:{"type":"radio","name":"gender","value":"male"},domProps:{"checked":_vm._q(_vm.gender,"male")},on:{"change":function($event){_vm.gender="male"}}}),_c('span',{staticClass:"ml-2 text-sm"},[_vm._v(_vm._s(_vm.$t("male")))])]),_c('label',{staticClass:"inline-flex items-center ml-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.gender),expression:"gender"}],staticClass:"w-4 h-4 form-radio",attrs:{"type":"radio","name":"gender","value":"female"},domProps:{"checked":_vm._q(_vm.gender,"female")},on:{"change":function($event){_vm.gender="female"}}}),_c('span',{staticClass:"ml-2 text-sm"},[_vm._v(_vm._s(_vm.$t("female")))])]),_c('div',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])]),_c('div',{staticClass:"w-full p-2 mb-2"},[_c('div',{staticClass:"relative"},[_c('label',{staticClass:"block mb-2 text-sm font-medium",attrs:{"for":"profession"}},[_vm._v(" "+_vm._s(_vm.$t("profession"))+" ")]),_c('ValidationProvider',{attrs:{"name":"Profession","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.profession),expression:"profession"}],staticClass:"w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-ghc-primary focus:ring-1 focus:ring-ghc-primary",attrs:{"type":"text","id":"profession","name":"profession","placeholder":_vm.$t('profession')},domProps:{"value":(_vm.profession)},on:{"input":function($event){if($event.target.composing){ return; }_vm.profession=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"w-full p-2 mb-2"},[_c('div',{staticClass:"relative"},[_c('label',{staticClass:"block mb-2 text-sm font-medium",attrs:{"for":"nationality"}},[_vm._v(" "+_vm._s(_vm.$t("nationality"))+" ")]),_c('ValidationProvider',{attrs:{"name":"Nationality","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.nationality),expression:"nationality"}],staticClass:"w-full px-3 py-1 text-sm leading-8 text-gray-700 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none bg-opacity-500",attrs:{"name":"nationality"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.nationality=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":""}},[_vm._v("Nationality")]),_vm._l((_vm.countries),function(country){return _c('option',{key:country.id,domProps:{"value":country.label}},[_vm._v(" "+_vm._s(country.label)+" ")])})],2),_c('span',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"w-full mt-8"},[_c('p',{staticClass:"block mb-2 font-bold"},[_vm._v(" "+_vm._s(_vm.$t("current-place-of-residence"))+" ")])]),_c('div',{staticClass:"w-full p-2 mb-2 sm:w-1/2"},[_c('div',{staticClass:"relative"},[_c('label',{staticClass:"block mb-2 text-sm font-medium",attrs:{"for":"streetAddress"}},[_vm._v(" "+_vm._s(_vm.$t("street-address"))+" ")]),_c('ValidationProvider',{attrs:{"name":"Street Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.streetAddress),expression:"streetAddress"}],staticClass:"w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-primary-greenish focus:ring-1 focus:ring-primary-greenish",attrs:{"type":"text","id":"sAddress","name":"streetAddress","placeholder":_vm.$t('street-address')},domProps:{"value":(_vm.streetAddress)},on:{"input":function($event){if($event.target.composing){ return; }_vm.streetAddress=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"w-full p-2 mb-2 sm:w-1/2"},[_c('div',{staticClass:"relative"},[_c('label',{staticClass:"block mb-2 text-sm font-medium",attrs:{"for":"city"}},[_vm._v(" "+_vm._s(_vm.$t("city"))+" ")]),_c('ValidationProvider',{attrs:{"name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.city),expression:"city"}],staticClass:"w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-primary-greenish focus:ring-1 focus:ring-primary-greenish",attrs:{"type":"text","id":"city","name":"city","placeholder":_vm.$t('city')},domProps:{"value":(_vm.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.city=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"w-full p-2 mb-2 sm:w-1/2"},[_c('div',{staticClass:"relative"},[_c('label',{staticClass:"block mb-2 text-sm font-medium",attrs:{"for":"state"}},[_vm._v(" "+_vm._s(_vm.$t("state-region"))+" ")]),_c('ValidationProvider',{attrs:{"name":"state/region","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.state),expression:"state"}],staticClass:"w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-primary-greenish focus:ring-1 focus:ring-primary-greenish",attrs:{"type":"text","id":"state","name":"state","placeholder":_vm.$t('state-region')},domProps:{"value":(_vm.state)},on:{"input":function($event){if($event.target.composing){ return; }_vm.state=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"w-full p-2 mb-2 sm:w-1/2"},[_c('div',{staticClass:"relative"},[_c('label',{staticClass:"block mb-2 text-sm font-medium",attrs:{"for":"zCode"}},[_vm._v(" "+_vm._s(_vm.$t("postal-zip-code"))+" ")]),_c('ValidationProvider',{attrs:{"name":"Postal/Zip code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.zipCode),expression:"zipCode"}],staticClass:"w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-primary-greenish focus:ring-1 focus:ring-primary-greenish",attrs:{"type":"text","id":"zCode","name":"zCode","placeholder":_vm.$t('postal-zip-code')},domProps:{"value":(_vm.zipCode)},on:{"input":function($event){if($event.target.composing){ return; }_vm.zipCode=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"w-full p-2 mb-2"},[_c('div',{staticClass:"relative"},[_c('label',{staticClass:"block mb-2 text-sm font-medium",attrs:{"for":"lName"}},[_vm._v(" "+_vm._s(_vm.$t("organization"))+" ")]),_c('ValidationProvider',{attrs:{"name":"Organization","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.organization),expression:"organization"}],staticClass:"w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-primary-greenish focus:ring-1 focus:ring-primary-greenish",attrs:{"type":"text","id":"organization","name":"organization","placeholder":_vm.$t('organization')},domProps:{"value":(_vm.organization)},on:{"input":function($event){if($event.target.composing){ return; }_vm.organization=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"w-full p-2 mb-2"},[_c('label',{staticClass:"block mb-2 text-sm font-medium",attrs:{"for":"phone"}},[_vm._v(" "+_vm._s(_vm.$t("phone-number"))+" ")]),_c('div',{staticClass:"relative"},[_c('ValidationProvider',{attrs:{"name":"Phone number","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VuePhoneNumberInput',{attrs:{"default-country-code":"ET","color":"#f00f00","no-example":""},on:{"update":_vm.onUpdate},model:{value:(_vm.nationalNumber),callback:function ($$v) {_vm.nationalNumber=$$v},expression:"nationalNumber"}}),_c('span',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"w-full p-2 mb-2"},[_c('div',{staticClass:"relative"},[_c('label',{staticClass:"block mb-2 text-sm font-medium",attrs:{"for":"email"}},[_vm._v(" "+_vm._s(_vm.$t("email"))+" ")]),_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-primary-greenish focus:ring-1 focus:ring-primary-greenish",attrs:{"type":"email","id":"email","name":"email","placeholder":_vm.$t('email')},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('span',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"w-full p-2 mb-2"},[_c('ValidationProvider',{attrs:{"name":"Terms","rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"flex items-center h-5"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.terms),expression:"terms"}],staticClass:"w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300",attrs:{"id":"terms","aria-describedby":"terms","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.terms)?_vm._i(_vm.terms,null)>-1:(_vm.terms)},on:{"change":function($event){var $$a=_vm.terms,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.terms=$$a.concat([$$v]))}else{$$i>-1&&(_vm.terms=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.terms=$$c}}}})]),_c('div',{staticClass:"ml-3 text-sm"},[_c('label',{staticClass:"font-medium",attrs:{"for":"terms"}},[_vm._v("I agree with the "),_c('router-link',{staticClass:"text-atn-primary hover:underline",attrs:{"to":"/terms-and-conditions"}},[_vm._v("Terms and conditions")])],1)])]),_c('span',{staticClass:"text-sm text-red-600"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"flex justify-end w-full p-2 mt-4"},[_c('button',{staticClass:"w-full px-4 py-2 text-white transition-colors duration-200 ease-in-out border-0 rounded bg-atn-primary focus:outline-none hover:opacity-90",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("join-today"))+" ")])])])])])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"mb-4"},[_vm._v(" Individual membership: Annual fee is "),_c('strong',[_vm._v("free")]),_vm._v(" for a limited time. ")])}]

export { render, staticRenderFns }