import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About";
import Events from "../views/Events.vue";
import Services from "../views/Services.vue";
import Resources from "../views/Resources.vue";
import Registration from "../views/Registration.vue";
import SuccessRegistration from "../components/SuccessRegistration.vue";
import Membership from "../views/Membership.vue";
import Terms from "../views/TermsAndConditions.vue";
import Contact from "../views/Contact.vue";
import TermsOfUse from "../views/TermsOfUse.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import MainLayout from "../layout/MainLayout.vue";
import LegalsLayout from "../layout/LegalsLayout.vue";

Vue.use(VueRouter);
// Vue.prototype.$scrollToTop = () =>{
//   window.scrollTo(0, 0)
// }
const routes = [
  {
    path: "/",
    component: MainLayout,
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
      },
      {
        path: "/about-us",
        name: "About",
        component: About,
      },
      {
        path: "/events",
        name: "Events",
        component: Events,
      },
      {
        path: "/services",
        name: "Services",
        component: Services,
      },
      {
        path: "/resources",
        name: "Resources",
        component: Resources,
      },
      {
        path: "/membership",
        name: "Membership",
        component: Membership,
      },
      {
        path: "/contact-us",
        name: "Contact",
        component: Contact,
      },
    ],
  },

  {
    path: "/legal",
    component: LegalsLayout,
    children: [
      {
        path: "/terms-of-use",
        name: "terms-of-use",
        component: TermsOfUse,
      },
      {
        path: "/privacy-policy",
        name: "privacy-policy",
        component: PrivacyPolicy,
      },
      {
        path: "/registration",
        name: "Registration",
        component: Registration,
      },
      {
        path: "/submitted",
        name: "SuccessRegistration",
        component: SuccessRegistration,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
