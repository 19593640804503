<template>
  <div class="text-white bg-atn-primary">
    <footer class="max-w-6xl py-8 mx-auto sm:py-12">
      <div class="px-6">
        <div
          class="grid content-start grid-cols-2 gap-10 md:grid-cols-4 sm:grid-cols-3"
        >
          <div class="">
            <h5 class="mb-4 text-lg font-bold">Sitemap</h5>
            <ul class="list-none footer-links">
              <li
                v-for="sitemap in sitemaps"
                :key="sitemap.name"
                class="mb-3 text-sm"
              >
                <router-link
                  @click.native="scrollToTop"
                  :to="sitemap.route"
                  class="transition-colors duration-300 hover:text-gray-400"
                >
                  {{ $t(`nav.${sitemap.name}`) }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="">
            <h5 class="mb-4 text-lg font-bold">{{ $t("contact-us") }}</h5>
            <div>
              <div>
                <h2 class="mb-2 text-sm font-bold">
                  {{ $t("physical-address") }}
                </h2>
                <p class="mt-1 text-sm leading-relaxed">
                  <VueShowdown :markdown="`${footer.PhysicalAddress}`" />
                </p>
              </div>
              <div>
                <h2 class="mt-4 text-sm font-bold">{{ $t("email") }}</h2>
                <a
                  href="mailto:info@ethiopiastroke.com"
                  class="text-xs leading-relaxed sm:text-sm"
                >
                  <VueShowdown :markdown="`${footer.Email}`" />
                </a>
                <h2 class="mt-4 text-sm font-bold">{{ $t("phone-number") }}</h2>
                <p class="text-sm leading-relaxed">
                  <VueShowdown :markdown="`${footer.Phone}`" />
                </p>
              </div>
            </div>
          </div>
          <div class="">
            <h5 class="mb-4 text-lg font-bold">Legals</h5>
            <ul class="list-none footer-links">
              <li class="mb-2 text-sm">
                <router-link
                  @click.native="scrollToTop"
                  to="/terms-of-use"
                  class="transition-colors duration-300 hover:text-gray-300"
                  >Terms of use</router-link
                >
              </li>
              <li class="mb-2 text-sm">
                <router-link
                  @click.native="scrollToTop"
                  to="/privacy-policy"
                  class="transition-colors duration-300 hover:text-gray-400"
                  >Privacy policy</router-link
                >
              </li>
            </ul>
          </div>
          <div class="">
            <h5 class="mb-4 text-lg font-bold">Stay connected</h5>
            <div class="flex flex-wrap space-x-2 md:space-x-6">
              <a
                v-for="item in social"
                :key="item.id"
                :href="item.url"
                target="_blank"
                rel="noreferrer noopener"
                class="px-1 py-1 rounded-full outline-none hover:bg-white focus:outline-none"
              >
                <img
                  :src="getBaseUrl() + item.image.url"
                  alt="socials"
                  class="w-6 h-6"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="pt-6 mt-6 sm:flex sm:flex-wrap sm:-mx-4 sm:mt-12 sm:pt-12">
          <div class="w-full px-4 text-sm text-center">
            <p>
              &copy;{{ new Date().getFullYear() }} ATN - African Telemedicine
              Network
            </p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../Api";
import { mapGetters } from "vuex";

export default {
  name: "Footer",
  data: () => ({
    footer: [],
    social: [],
    sitemaps: [
      {
        name: "home",
        route: "/",
      },
      {
        name: "about-us",
        route: "/about-us",
      },
      {
        name: "events",
        route: "/events",
      },
      {
        name: "services",
        route: "/services",
      },
      {
        name: "resources",
        route: "/resources",
      },
      {
        name: "membership",
        route: "/membership",
      },
      {
        name: "contact-us",
        route: "/contact-us",
      },
    ],
  }),
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      apiFooter: "LanguageStore/apiFooters",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    getBaseUrl() {
      return baseUrl;
    },
    async getData() {
      try {
        const social = await axios.get(`${baseUrl}/socials`);
        this.social = social.data;

        const footer = await axios.get(this.apiFooter);
        this.footer = footer.data;

        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style></style>
