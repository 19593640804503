<template>
  <div>
    <div class="flex items-center justify-center h-72 hero">
      <div class="text-center text-gray-200">
        <h1 class="mb-3 text-5xl font-extrabold">Services</h1>
        <p class="text-lg font-medium">
          Telemedicine specialties currently practiced by the global
          Telemedicine community.
        </p>
      </div>
    </div>
    <section class="max-w-6xl px-6 py-20 mx-auto">
      <div class="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
        <div
          class="flex justify-center"
          v-for="service in services"
          :key="service.id"
        >
          <div class="w-full bg-white rounded-lg md:max-w-sm">
            <div class="h-40">
              <img
                :src="getBaseUrl() + service.Image.url"
                :alt="service.ServiceName"
                class="object-contain object-center w-full h-40 p-6 transition duration-300 ease-in-out rounded-t-lg hover:scale-110"
              />
            </div>
            <div class="p-6">
              <h5
                class="mb-2 text-xl font-semibold text-center text-gray-900 uppercase"
              >
                {{ service.ServiceName }}
              </h5>
              <p class="mb-4 text-base text-gray-700">
                <VueShowdown :markdown="service.description" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";
import { mapGetters } from "vuex";

export default {
  name: "Services",
  data() {
    return {
      services: "",
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiServices",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    async getData() {
      try {
        const response = await axios.get(this.api);
        this.services = response.data;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
  async mounted() {
    try {
      const services = await axios.get(`${baseUrl}/services`);
      this.services = services.data;
    } catch (error) {
      this.error = error;
    }
  },
};
</script>

<style scoped>
.hero {
  background-image: linear-gradient(
      to right bottom,
      rgba(93, 168, 161, 0.8),
      rgba(0, 10, 34, 0.8)
    ),
    url("../assets/3.png");
  background-position: center;
}
</style>
