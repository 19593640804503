<template>
  <div
    class="h-screen max-w-2xl px-3 py-6 mx-auto text-gray-600 sm:py-10 sm:px-6"
  >
    <div
      class="p-2 bg-white border-t-8 border-green-500 rounded-md shadow-sm sm:p-6 sm:shadow"
    >
      <div class="px-2 mb-6">
        <h1 class="mb-2 text-xl font-bold">
          African Telemedicine Network Conference
        </h1>
        <p>
          Thank you for completing the questionnaire. We will send you the
          formal invitation card via email if your application for attendance is
          accepted.
        </p>
        <p class="mt-4">African Telemedicine Network (ATN)</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
