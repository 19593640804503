<template>
  <div>
    <nav class="px-10 py-4 mx-auto bg-white">
      <div class="max-w-screen-xl mx-auto lg:flex lg:flex-wrap lg:items-center">
        <div class="flex items-center justify-between">
          <div class="flex flex-col items-center justify-center">
            <router-link to="/">
              <img class="h-16" src="../assets/ATN.svg" alt="" />
            </router-link>
          </div>
        </div>
      </div>
    </nav>
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
