import { baseUrl } from "@/Api";
export default {
  namespaced: true,
  state: {
    lang: localStorage.getItem("lang") || "en",
  },
  mutations: {
    SET_LANG(state, payload) {
      state.lang = payload;
    },
  },
  actions: {
    doChangeLanguage({ commit, state }, payload) {
      // state change + localstorage
      commit("SET_LANG", payload);
    },
  },
  getters: {
    lang(state) {
      return state.lang;
    },
    apiServices(state) {
      return `${baseUrl}/services?_locale=${state.lang}`;
    },
    apiEvents(state) {
      return `${baseUrl}/events?_locale=${state.lang}`;
    },
    apiWhatIsTelemedicine(state) {
      return `${baseUrl}/what-is-telemedicine?_locale=${state.lang}`;
    },
    apiWhyTelemedicine(state) {
      return `${baseUrl}/telemedicine-whies?_locale=${state.lang}`;
    },
    apiValues(state) {
      return `${baseUrl}/values?_locale=${state.lang}`;
    },
    apiAbouts(state) {
      return `${baseUrl}/abouts?_locale=${state.lang}`;
    },
    apiVission(state) {
      return `${baseUrl}/vission?_locale=${state.lang}`;
    },
    apiMission(state) {
      return `${baseUrl}/mission?_locale=${state.lang}`;
    },
    apiFooters(state) {
      return `${baseUrl}/footer?_locale=${state.lang}`;
    },
  },
};
