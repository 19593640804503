<template>
  <div>
    <div class="flex items-center justify-center h-72 hero"></div>
    <section class="text-gray-700">
      <Mission></Mission>
      <Vision></Vision>
      <Values></Values>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import { baseUrl } from "../Api";
import Values from "../components/aboutUs/Values.vue";
import Vision from "../components/aboutUs/Vision.vue";
import Mission from "../components/aboutUs/Mission.vue";

export default {
  name: "About",
  components: {
    Values,
    Vision,
    Mission,
  },
  data() {
    return {
      loading: false,
      error: null,
    };
  },
};
</script>
<style scoped>
.hero {
  background-image: linear-gradient(
      to right bottom,
      rgba(93, 168, 161, 0.8),
      rgba(0, 10, 34, 0.8)
    ),
    url("../assets/3.png");
  background-position: center;
}
</style>
