<template>
  <div>
    <section class="max-w-3xl px-6 py-20 mx-auto text-gray-600">
      <div class="p-10 bg-white rounded shadow">
        <p class="mb-4">
          Individual membership: Annual fee is <strong>free</strong> for a
          limited time.
        </p>
        <p class="mb-8 text-sm">
          ATN brings together healthcare professionals, medical students and
          telemedicine enthusiasts to advance telemedicine in Africa. ATN
          provides unique learning and networking opportunities to its members
          through networking and professional development events.
        </p>
        <ValidationObserver ref="form">
          <form @submit.prevent="onSubmit">
            <div class="flex flex-wrap">
              <div class="w-full p-2 mb-2 sm:w-1/2">
                <div class="relative">
                  <label for="fName" class="block mb-2 text-sm font-medium">
                    {{ $t("first-name") }}
                  </label>
                  <ValidationProvider
                    name="First name"
                    rules="required|alpha"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      id="fName"
                      v-model="fName"
                      name="firstName"
                      :placeholder="$t('first-name')"
                      class="w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none"
                    />
                    <span class="text-sm text-red-600">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="w-full p-2 mb-2 sm:w-1/2">
                <div class="relative">
                  <label for="lName" class="block mb-2 text-sm font-medium">
                    {{ $t("last-name") }}
                  </label>
                  <ValidationProvider
                    name="Last name"
                    rules="required|alpha"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      id="lName"
                      v-model="lName"
                      name="lastName"
                      :placeholder="$t('last-name')"
                      class="w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none"
                    />
                    <span class="text-sm text-red-600">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="w-full p-2 mb-2 sm:w-1/2">
                <div class="relative">
                  <label for="title" class="block mb-2 text-sm font-medium">
                    {{ $t("title") }}
                  </label>
                  <ValidationProvider
                    name="Title"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <select
                      name="title"
                      v-model="title"
                      id="title"
                      class="w-full px-3 py-1 text-sm leading-8 text-gray-700 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none"
                    >
                      <option value="">Title</option>
                      <option value="Mr">Mr</option>
                      <option value="Miss">Miss</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Dr.">Dr.</option>
                      <option value="Prof">Prof</option>
                      <option value="other">other</option>
                    </select>
                    <span class="text-sm text-red-600">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="w-full p-2 mb-2 sm:w-1/2">
                <div class="relative">
                  <label for="title" class="block mb-2 text-sm font-medium">
                    {{ $t("age") }}
                  </label>
                  <ValidationProvider
                    name="Age"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <select
                      name="age"
                      v-model="age"
                      id="age"
                      class="w-full px-3 py-1 text-sm leading-8 text-gray-700 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none"
                    >
                      <option value="">Age</option>
                      <option value="Under 12 years">Under 12 years</option>
                      <option value="12-17 years old">12-17 years old</option>
                      <option value="18-24 years old">18-24 years old</option>
                      <option value="25-34 years old">25-34 years old</option>
                      <option value="35-44 years old">35-44 years old</option>
                      <option value="45-54 years old">45-54 years old</option>
                      <option value="55-64 years old">55-64 years old</option>
                      <option value="65-74 years old">65-74 years old</option>
                      <option value="75 years or older">
                        75 years or older
                      </option>
                    </select>
                    <span class="text-sm text-red-600">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="flex w-full p-2 my-8 item-center bg-gray-50">
                <div class="p-4">
                  <span class="block mb-2 text-sm font-medium">
                    {{ $t("gender") }}
                  </span>
                  <div class="mt-2">
                    <ValidationProvider
                      name="Type Of Appointment"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label class="inline-flex items-center">
                        <input
                          v-model="gender"
                          type="radio"
                          class="w-4 h-4 form-radio"
                          name="gender"
                          value="male"
                        />
                        <span class="ml-2 text-sm">{{ $t("male") }}</span>
                      </label>
                      <label class="inline-flex items-center ml-6">
                        <input
                          v-model="gender"
                          type="radio"
                          class="w-4 h-4 form-radio"
                          name="gender"
                          value="female"
                        />
                        <span class="ml-2 text-sm">{{ $t("female") }}</span>
                      </label>
                      <div class="text-sm text-red-600">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="w-full p-2 mb-2">
                <div class="relative">
                  <label
                    for="profession"
                    class="block mb-2 text-sm font-medium"
                  >
                    {{ $t("profession") }}
                  </label>
                  <ValidationProvider
                    name="Profession"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      id="profession"
                      v-model="profession"
                      name="profession"
                      :placeholder="$t('profession')"
                      class="w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-ghc-primary focus:ring-1 focus:ring-ghc-primary"
                    />
                    <span class="text-sm text-red-600">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="w-full p-2 mb-2">
                <div class="relative">
                  <label
                    for="nationality"
                    class="block mb-2 text-sm font-medium"
                  >
                    {{ $t("nationality") }}
                  </label>
                  <ValidationProvider
                    name="Nationality"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <select
                      v-model="nationality"
                      name="nationality"
                      class="w-full px-3 py-1 text-sm leading-8 text-gray-700 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none bg-opacity-500"
                    >
                      <option value="">Nationality</option>
                      <option
                        v-for="country in countries"
                        :value="country.label"
                        :key="country.id"
                      >
                        {{ country.label }}
                      </option>
                    </select>
                    <!-- <input type="text" id="nationality" v-model="nationality" name="nationality" placeholder="Nationality" class="w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-ghc-primary focus:ring-1 focus:ring-ghc-primary" /> -->
                    <span class="text-sm text-red-600">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="w-full mt-8">
                <p class="block mb-2 font-bold">
                  {{ $t("current-place-of-residence") }}
                </p>
              </div>
              <div class="w-full p-2 mb-2 sm:w-1/2">
                <div class="relative">
                  <label
                    for="streetAddress"
                    class="block mb-2 text-sm font-medium"
                  >
                    {{ $t("street-address") }}
                  </label>
                  <ValidationProvider
                    name="Street Address"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      id="sAddress"
                      v-model="streetAddress"
                      name="streetAddress"
                      :placeholder="$t('street-address')"
                      class="w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-primary-greenish focus:ring-1 focus:ring-primary-greenish"
                    />
                    <span class="text-sm text-red-600">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="w-full p-2 mb-2 sm:w-1/2">
                <div class="relative">
                  <label for="city" class="block mb-2 text-sm font-medium">
                    {{ $t("city") }}
                  </label>
                  <ValidationProvider
                    name="city"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      id="city"
                      v-model="city"
                      name="city"
                      :placeholder="$t('city')"
                      class="w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-primary-greenish focus:ring-1 focus:ring-primary-greenish"
                    />
                    <span class="text-sm text-red-600">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="w-full p-2 mb-2 sm:w-1/2">
                <div class="relative">
                  <label for="state" class="block mb-2 text-sm font-medium">
                    {{ $t("state-region") }}
                  </label>
                  <ValidationProvider
                    name="state/region"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      id="state"
                      v-model="state"
                      name="state"
                      :placeholder="$t('state-region')"
                      class="w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-primary-greenish focus:ring-1 focus:ring-primary-greenish"
                    />
                    <span class="text-sm text-red-600">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="w-full p-2 mb-2 sm:w-1/2">
                <div class="relative">
                  <label for="zCode" class="block mb-2 text-sm font-medium">
                    {{ $t("postal-zip-code") }}
                  </label>
                  <ValidationProvider
                    name="Postal/Zip code"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      id="zCode"
                      v-model="zipCode"
                      name="zCode"
                      :placeholder="$t('postal-zip-code')"
                      class="w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-primary-greenish focus:ring-1 focus:ring-primary-greenish"
                    />
                    <span class="text-sm text-red-600">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="w-full p-2 mb-2">
                <div class="relative">
                  <label for="lName" class="block mb-2 text-sm font-medium">
                    {{ $t("organization") }}
                  </label>
                  <ValidationProvider
                    name="Organization"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <input
                      type="text"
                      id="organization"
                      v-model="organization"
                      name="organization"
                      :placeholder="$t('organization')"
                      class="w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-primary-greenish focus:ring-1 focus:ring-primary-greenish"
                    />
                    <span class="text-sm text-red-600">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="w-full p-2 mb-2">
                <label for="phone" class="block mb-2 text-sm font-medium">
                  {{ $t("phone-number") }}
                </label>
                <div class="relative">
                  <ValidationProvider
                    name="Phone number"
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <VuePhoneNumberInput
                      v-model="nationalNumber"
                      @update="onUpdate"
                      default-country-code="ET"
                      color="#f00f00"
                      no-example
                    />
                    <span class="text-sm text-red-600">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="w-full p-2 mb-2">
                <div class="relative">
                  <label for="email" class="block mb-2 text-sm font-medium">
                    {{ $t("email") }}
                  </label>
                  <ValidationProvider
                    name="Email"
                    rules="required|email"
                    v-slot="{ errors }"
                  >
                    <input
                      type="email"
                      id="email"
                      v-model="email"
                      name="email"
                      :placeholder="$t('email')"
                      class="w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-primary-greenish focus:ring-1 focus:ring-primary-greenish"
                    />
                    <span class="text-sm text-red-600">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="w-full p-2 mb-2">
                <ValidationProvider
                  name="Terms"
                  :rules="{ required: { allowFalse: false } }"
                  v-slot="{ errors }"
                >
                  <div class="flex items-start">
                    <div class="flex items-center h-5">
                      <input
                        id="terms"
                        aria-describedby="terms"
                        v-model="terms"
                        type="checkbox"
                        class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="terms" class="font-medium"
                        >I agree with the
                        <router-link
                          class="text-atn-primary hover:underline"
                          to="/terms-and-conditions"
                          >Terms and conditions</router-link
                        >
                        <!-- <a href="/Terms" class="text-atn-primary hover:underline">Terms and Conditions</a> -->
                      </label>
                    </div>
                  </div>
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <div class="flex justify-end w-full p-2 mt-4">
                <button
                  type="submit"
                  class="w-full px-4 py-2 text-white transition-colors duration-200 ease-in-out border-0 rounded bg-atn-primary focus:outline-none hover:opacity-90"
                >
                  {{ $t("join-today") }}
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";
import VuePhoneNumberInput from "vue-phone-number-input";
const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default {
  components: {
    VuePhoneNumberInput,
  },
  data() {
    return {
      fName: "",
      lName: "",
      title: "",
      organization: "",
      age: "",
      gender: "",
      profession: "",
      nationality: "",
      streetAddress: "",
      state: "",
      city: "",
      zipCode: "",
      phone: "",
      nationalNumber: "",
      email: "",
      terms: "",
    };
  },
  computed: {
    countries() {
      const list = countries.getNames("en", { select: "official" });
      return Object.keys(list).map((key) => ({ value: key, label: list[key] }));
    },
  },
  methods: {
    onUpdate: function (payload) {
      this.phone = payload.formatInternational;
    },
    showToast() {
      this.$toast.success(
        "Your application for membership has been successfully submitted"
      );
    },
    async onSubmit(e) {
      const success = await this.$refs.form.validate();
      if (success) {
        try {
          axios
            .post(`${baseUrl}/memberships`, {
              firstName: this.fName,
              lastName: this.lName,
              title: this.title,
              age: this.age,
              city: this.city,
              profession: this.profession,
              gender: this.gender,
              streetAddress: this.streetAddress,
              zipCode: this.zipCode,
              state: this.state,
              nationality: this.nationality,
              organization: this.organization,
              phone: this.phone,
              email: this.email,
            })
            .then(function (response) {
              console.log(response);
            })
            .catch(function (error) {
              console.log(error);
            });
          this.$nextTick(() => {
            // this.$refs.form.reset();
            // this.fName = "";
            // this.lName = "";
            // this.phone = "";
            // this.email = "";
            this.showToast();
          });
        } catch (error) {
          console.log({ error });
        }
      } else {
        this.error = true;
      }
    },
  },
};
</script>

<style></style>
