<template>
  <div class="mb-6 sm:pr-10">
    <h1 class="mb-4 text-4xl font-bold text-atn-primary">
      {{ telemedicine.title }}
    </h1>
    <p class="text-gray-700">
      <VueShowdown :markdown="`${telemedicine.definition}`" />
    </p>
  </div>
</template>
<script>
import axios from "axios";
import { baseUrl } from "../../Api";
import { mapGetters } from "vuex";

export default {
  name: "WhatIsTelemedicine",
  data() {
    return {
      telemedicine: "",
    };
  },
  computed: {
    ...mapGetters({
      lang: "LanguageStore/lang",
      api: "LanguageStore/apiWhatIsTelemedicine",
    }),
  },
  watch: {
    async lang() {
      this.loading = true;
      await this.getData();
    },
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    scrollToTop() {
      this.open = !this.open;
      window.scrollTo(0, 0);
    },
    async getData() {
      try {
        const response = await axios.get(this.api);
        this.telemedicine = response.data;
        this.loading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>
<style></style>
