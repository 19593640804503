<template>
  <div class="max-w-2xl py-6 mx-auto text-gray-600 sm:py-10">
    <div class="p-2">
      <div class="p-6 mb-6 bg-white rounded-lg">
        <h1 class="mb-2 text-2xl font-bold">
          African Telemedicine Network Conference
        </h1>
        <div class="mt-3">
          <p class="mb-3">
            Would you like to be part of the African digital health community
            and enjoy the privilege of being one of the early adopters?
          </p>
          <p>
            If the answer is Yes, please kindly complete the following
            questionnaire and join us at the first African Telemedicine Network
            conference, which will be held on December 16 at the Ethiopian Sky
            Light Hotel in Addis Ababa, Ethiopia.
          </p>
        </div>
      </div>
      <ValidationObserver ref="form">
        <form @submit.prevent="onSubmit">
          <div class="flex flex-wrap">
            <div
              class="w-full p-6 mb-6 bg-white border border-gray-200 rounded-lg"
            >
              <div class="relative">
                <label
                  for="fName"
                  class="block mb-1.5 font-medium tracking-tight"
                >
                  Full name <span class="text-red-700">*</span>
                </label>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <input
                    type="text"
                    id="fName"
                    v-model="fName"
                    placeholder="Your full name"
                    name="Full name"
                    class="w-full py-1 pl-0 text-sm leading-8 transition-colors duration-200 ease-in-out border-t-0 border-l-0 border-r-0 border-gray-300 border-b-1 focus:bg-white focus:outline-none focus:ring-0 focus:border-atn-primary focus:ring-atn-primary"
                  />
                  <div class="flex items-center mt-3">
                    <svg
                      v-if="errors[0]"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6 mr-3 text-red-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                      />
                    </svg>
                    <span class="text-sm text-red-700">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div
              class="w-full p-6 mb-6 bg-white border border-gray-200 rounded-lg"
            >
              <div class="relative">
                <label
                  for="title"
                  class="block mb-1.5 font-medium tracking-tight"
                >
                  Title <span class="text-red-700">*</span>
                </label>
                <ValidationProvider
                  name="Title"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <select
                    name="title"
                    v-model="title"
                    id="title"
                    class="w-full py-1 pl-0 text-sm leading-8 transition-colors duration-200 ease-in-out border-t-0 border-l-0 border-r-0 border-gray-300 border-b-1 focus:bg-white focus:outline-none focus:ring-0 focus:border-atn-primary focus:ring-atn-primary"
                  >
                    <option value="">Your title</option>
                    <option value="Mr">Mr</option>
                    <option value="Miss">Miss</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Dr.">Dr</option>
                    <option value="Prof">Prof</option>
                    <option value="other">other</option>
                  </select>
                  <div class="flex items-center mt-3">
                    <svg
                      v-if="errors[0]"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6 mr-3 text-red-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                      />
                    </svg>
                    <span class="text-sm text-red-700">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div
              class="w-full p-6 mb-6 bg-white border border-gray-200 rounded-lg"
            >
              <div class="relative">
                <label
                  for="nationality"
                  class="block mb-1.5 font-medium tracking-tight"
                >
                  Nationality <span class="text-red-700">*</span>
                </label>
                <ValidationProvider
                  name="Nationality"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <select
                    v-model="country"
                    name="country"
                    class="w-full py-1 pl-0 text-sm leading-8 transition-colors duration-200 ease-in-out border-t-0 border-l-0 border-r-0 border-gray-300 border-b-1 focus:bg-white focus:outline-none focus:ring-0 focus:border-atn-primary focus:ring-atn-primary"
                  >
                    <option value="">Your country</option>
                    <option
                      v-for="country in countries"
                      :value="country.label"
                      :key="country.id"
                    >
                      {{ country.label }}
                    </option>
                  </select>
                  <!-- <input type="text" id="country" v-model="nationality" name="nationality" placeholder="Nationality" class="w-full px-3 py-1 text-sm leading-8 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded outline-none focus:border-ghc-primary focus:ring-1 focus:ring-ghc-primary" /> -->
                  <div class="flex items-center mt-3">
                    <svg
                      v-if="errors[0]"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6 mr-3 text-red-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                      />
                    </svg>
                    <span class="text-sm text-red-700">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div
              class="w-full p-6 mb-6 bg-white border border-gray-200 rounded-lg"
            >
              <div class="relative">
                <label
                  for="lName"
                  class="block mb-1.5 font-medium tracking-tight"
                >
                  Company name <span class="text-red-700">*</span>
                </label>
                <ValidationProvider
                  name="Company"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                    id="company"
                    v-model="company"
                    name="company"
                    placeholder="Your company"
                    class="w-full py-1 pl-0 text-sm leading-8 transition-colors duration-200 ease-in-out border-t-0 border-l-0 border-r-0 border-gray-300 border-b-1 focus:bg-white focus:outline-none focus:ring-0 focus:border-atn-primary focus:ring-atn-primary"
                  />
                  <div class="flex items-center mt-3">
                    <svg
                      v-if="errors[0]"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6 mr-3 text-red-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                      />
                    </svg>
                    <span class="text-sm text-red-700">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div
              class="w-full p-6 mb-6 bg-white border border-gray-200 rounded-lg"
            >
              <div class="relative">
                <label
                  for="position"
                  class="block mb-1.5 font-medium tracking-tight"
                >
                  Position <span class="text-red-700">*</span>
                </label>
                <ValidationProvider
                  name="Position"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                    id="position"
                    v-model="position"
                    name="osition"
                    placeholder="Your position"
                    class="w-full py-1 pl-0 text-sm leading-8 transition-colors duration-200 ease-in-out border-t-0 border-l-0 border-r-0 border-gray-300 border-b-1 focus:bg-white focus:outline-none focus:ring-0 focus:border-atn-primary focus:ring-atn-primary"
                  />
                  <div class="flex items-center mt-3">
                    <svg
                      v-if="errors[0]"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6 mr-3 text-red-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                      />
                    </svg>
                    <span class="text-sm text-red-700">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div
              class="w-full p-6 mb-6 bg-white border border-gray-200 rounded-lg"
            >
              <div class="relative">
                <label
                  for="profession"
                  class="block mb-1.5 font-medium tracking-tight"
                >
                  Profession <span class="text-red-700">*</span>
                </label>
                <ValidationProvider
                  name="Profession"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                    id="profession"
                    v-model="profession"
                    name="profession"
                    placeholder="Your profession"
                    class="w-full py-1 pl-0 text-sm leading-8 transition-colors duration-200 ease-in-out border-t-0 border-l-0 border-r-0 border-gray-300 border-b-1 focus:bg-white focus:outline-none focus:ring-0 focus:border-atn-primary focus:ring-atn-primary"
                  />
                  <div class="flex items-center mt-3">
                    <svg
                      v-if="errors[0]"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6 mr-3 text-red-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                      />
                    </svg>
                    <span class="text-sm text-red-700">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div
              class="w-full p-6 mb-6 bg-white border border-gray-200 rounded-lg"
            >
              <label
                for="phone"
                class="block mb-1.5 font-medium tracking-tight"
              >
                Phone number <span class="text-red-700">*</span>
              </label>
              <div class="relative">
                <ValidationProvider
                  name="Phone number"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <VuePhoneNumberInput
                    v-model="nationalNumber"
                    @update="onUpdate"
                    default-country-code="ET"
                    color="#f00f00"
                    no-example
                    class="bg-gray-100"
                  />
                  <div class="flex items-center mt-3">
                    <svg
                      v-if="errors[0]"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6 mr-3 text-red-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                      />
                    </svg>
                    <span class="text-sm text-red-700">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div
              class="w-full p-6 mb-6 bg-white border border-gray-200 rounded-lg"
            >
              <div class="relative">
                <label
                  for="email"
                  class="block mb-1.5 font-medium tracking-tight"
                >
                  Email <span class="text-red-700">*</span>
                </label>
                <ValidationProvider
                  name="Email"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <input
                    type="email"
                    id="email"
                    autocomplete="off"
                    v-model="email"
                    placeholder="Your email"
                    name="email"
                    class="w-full py-1 pl-0 text-sm leading-8 transition-colors duration-200 ease-in-out border-t-0 border-l-0 border-r-0 border-gray-300 border-b-1 focus:bg-white focus:outline-none focus:ring-0 focus:border-atn-primary focus:ring-atn-primary"
                  />
                  <div class="flex items-center mt-3">
                    <svg
                      v-if="errors[0]"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6 mr-3 text-red-700"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                      />
                    </svg>
                    <span class="text-sm text-red-700">{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </div>
            </div>

            <div>
              <div
                v-for="questionnaire in questionnaires.slice(0, 4)"
                :key="questionnaire.id"
                class="relative w-full p-6 mb-6 bg-white border border-gray-200 rounded-lg"
              >
                <div class="mb-4">
                  <label for="question7">
                    {{ questionnaire.question }}
                  </label>
                </div>
                <div
                  class="pt-2 pb-2 pr-2 form-check"
                  v-for="choice in questionnaire.choices"
                  :key="choice"
                >
                  <input
                    class="float-left w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border-2 border-gray-500 rounded-full appearance-none cursor-pointer form-check-input checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
                    type="radio"
                    :name="questionnaire.name"
                    :id="choice"
                    :value="choice"
                    v-model="questionnaire.answer"
                  />
                  <label class="inline-block form-check-label" for="yes">
                    {{ choice }}
                  </label>
                </div>
                <div class="flex justify-end" v-if="questionnaire.answer">
                  <button
                    @click.prevent="clearSelection(questionnaire.id)"
                    class="px-3 text-sm font-medium leading-9 tracking-wide transition-colors duration-200 ease-in-out border-0 rounded focus:outline-none hover:bg-gray-100"
                  >
                    Clear selection
                  </button>
                </div>
              </div>
              <div
                class="w-full p-6 mb-6 bg-white border border-gray-200 rounded-lg"
              >
                <div class="relative">
                  Please Answer the Following Questions as a Patient:
                </div>
              </div>
              <div
                v-for="questionnaire in questionnaires.slice(4)"
                :key="questionnaire.id"
                class="relative w-full p-6 mb-6 bg-white border border-gray-200 rounded-lg"
              >
                <div class="mb-4">
                  <label for="question7">
                    {{ questionnaire.question }}
                  </label>
                </div>
                <div
                  class="pt-2 pb-2 pr-2 form-check"
                  v-for="choice in questionnaire.choices"
                  :key="choice"
                >
                  <input
                    class="float-left w-4 h-4 mt-1 mr-2 align-top transition duration-200 bg-white bg-center bg-no-repeat bg-contain border-2 border-gray-500 rounded-full appearance-none cursor-pointer form-check-input checked:bg-blue-600 checked:border-blue-600 focus:outline-none"
                    type="radio"
                    :name="questionnaire.name"
                    :id="choice"
                    :value="choice"
                    v-model="questionnaire.answer"
                  />
                  <label class="inline-block form-check-label" for="yes">
                    {{ choice }}
                  </label>
                </div>
                <div class="flex justify-end" v-if="questionnaire.answer">
                  <button
                    @click.prevent="clearSelection(questionnaire.id)"
                    class="px-3 text-sm font-medium leading-9 tracking-wide transition-colors duration-200 ease-in-out border-0 rounded focus:outline-none hover:bg-gray-100"
                  >
                    Clear selection
                  </button>
                </div>
              </div>
            </div>
            <div class="flex justify-between w-full mt-2">
              <button
                type="submit"
                class="px-6 text-sm font-medium leading-9 tracking-wide text-white transition-colors duration-200 ease-in-out border-0 rounded bg-atn-primary focus:outline-none hover:bg-opacity-90"
              >
                Submit
              </button>
              <button
                @click.prevent="clearAllSelection()"
                class="px-3 text-sm font-medium leading-9 tracking-wide transition-colors duration-200 ease-in-out border-0 rounded focus:outline-none hover:bg-gray-200"
              >
                Clear form
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../Api";
import VuePhoneNumberInput from "vue-phone-number-input";
const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default {
  components: {
    VuePhoneNumberInput,
  },
  data() {
    return {
      fName: "",
      title: "",
      country: "",
      company: "",
      position: "",
      profession: "",
      nationalNumber: "",
      email: "",
      questionnaires: [
        {
          question:
            "Do you believe telemedicine is going to be an important service delivery modality in Africa?",
          choices: ["Yes", "No", "Maybe"],
          name: "radio1",
          answer: "",
          id: 0,
        },
        {
          question:
            "Would you consider incorporating telemedicine service into your practice?",
          choices: ["Yes", "No", "Maybe"],
          name: "radio2",
          answer: "",
          id: 1,
        },
        {
          question:
            "Do you think telemedicine could improve compliance/adherence to treatment for your patients?",
          choices: ["Yes", "No", "Maybe"],
          name: "radio3",
          answer: "",
          id: 2,
        },
        {
          question:
            "Would you say the majority of your patients will be happy using telemedicine service?",
          choices: ["Yes", "No", "Maybe"],
          name: "radio4",
          answer: "",
          id: 3,
        },
        {
          question:
            "Is transportation to visit your doctor or healthcare facility a major challenge?",
          choices: ["Yes", "No", "Maybe"],
          name: "radio5",
          answer: "",
          id: 4,
        },
        {
          question:
            "Is waiting in line to see your doctor a major inconvenience for you?",
          choices: ["Yes", "No", "Maybe"],
          name: "radio6",
          answer: "",
          id: 5,
        },
        {
          question:
            "As a patient, would you be comfortable receiving care from your doctor via telemedicine?",
          choices: ["Yes", "No", "Maybe"],
          name: "radio7",
          answer: "",
          id: 6,
        },
      ],
    };
  },
  computed: {
    countries() {
      const list = countries.getNames("en", { select: "official" });
      return Object.keys(list).map((key) => ({ value: key, label: list[key] }));
    },
    formFields() {
      let richText = "";
      for (let i = 0; i < this.questionnaires.length; i++) {
        richText +=
          i +
          1 +
          ". " +
          this.questionnaires[i].question +
          " " +
          "<b>" +
          this.questionnaires[i].answer +
          "</b>" +
          "</br>";
      }
      return richText;
    },
  },
  methods: {
    onUpdate: function (payload) {
      this.phone = payload.formatInternational;
    },
    clearSelection(index) {
      this.questionnaires[index].answer = "";
    },
    clearAllSelection() {
      for (let i = 0; i < this.questionnaires.length; i++) {
        this.questionnaires[i].answer = "";
      }
      this.fName = "";
      this.email = "";
      this.title = "";
      this.country = "";
      this.company = "";
      this.position = "";
      this.profession = "";
      this.nationalNumber = "";
    },

    showToast() {
      this.$toast.success(
        "We have receievd your registration and we will get back to you"
      );
    },
    async onSubmit(e) {
      const success = await this.$refs.form.validate();
      if (success) {
        try {
          axios
            .post(`${baseUrl}/registrations`, {
              fullName: this.fName,
              title: this.title,
              nationality: this.country,
              companyName: this.company,
              position: this.position,
              profession: this.profession,
              phoneNumber: this.nationalNumber,
              email: this.email,
              form: this.formFields,
            })
            .then((response) => {
              if (!response.data.error) {
                axios.post(
                  "https://aem.adeymeselesh.de/api/v1/organization/api_senderWithUserNameAndPassword",
                  {
                    body: {
                      // from:"destination email server",
                      // to: "user email"
                      from: "info@africantelemed.com",
                      to: this.email,
                      // cc: "niguszinie@gmail.com",
                      // bcc: "hailu1221tedros@gmail.com",
                      subject: "Successfully submitted",
                      text: "Thanks for contacting us",
                    },
                    credentials: {
                      userName: "info@africantelemed.com",
                      password: "change@password",
                    },
                  }
                );
              }
            })
            .then((response) => {
              this.$router.push({
                name: "SuccessRegistration",
              });
            });
        } catch (error) {
          console.log(error.message);
        }
      } else {
        console.log("Couldn't post email");
      }
    },
  },
};
</script>

<style scoped></style>
