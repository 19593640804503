<template>
  <section class="relative max-w-6xl px-6 py-20 mx-auto text-gray-600">
    <div class="flex flex-wrap mx-auto space-y-20 lg:space-y-0">
      <div
        class="relative flex items-end justify-start w-full overflow-hidden bg-gray-300 rounded-sm h-96 lg:h-auto lg:w-2/3"
      >
        <iframe
          width="100%"
          height="100%"
          class=""
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src="https://maps.google.com/maps?width=100%25&amp;height=100%25&amp;hl=en&amp;q=7412%20Eldorado%20Street%20McLean,%20VA%2022102+()&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        >
        </iframe>
      </div>
      <div
        class="flex flex-col w-full mt-8 bg-white lg:pl-6 lg:w-1/3 md:py-8 lg:py-0 md:mt-0 sm:w-4/5 sm:mx-auto"
      >
        <div class="p-4">
          <h2 class="mb-1 text-2xl font-bold text-gray-900">
            {{ $t("contact-us") }}
          </h2>
          <p class="mb-3 leading-relaxed">
            {{ $t("leave-us-a-message-here") }}
          </p>
          <ValidationObserver ref="form">
            <form @submit.prevent="onSubmit">
              <div class="relative mb-4">
                <label for="name" class="text-sm leading-7 text-gray-600">
                  {{ $t("name") }}
                </label>
                <ValidationProvider
                  name="Name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                    id="name"
                    v-model="name"
                    name="name"
                    :placeholder="$t('name')"
                    class="w-full px-3 py-1 text-sm leading-8 text-gray-700 placeholder-gray-400 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded-sm outline-none focus:border-primary-greenish focus:bg-white"
                  />
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="relative mb-4">
                <label for="email" class="text-sm leading-7 text-gray-600">
                  {{ $t("email") }}
                </label>
                <ValidationProvider
                  name="Email"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <input
                    type="email"
                    id="email"
                    v-model="email"
                    name="email"
                    :placeholder="$t('email')"
                    class="w-full px-3 py-1 text-sm leading-8 text-gray-700 placeholder-gray-400 transition-colors duration-200 ease-in-out bg-opacity-50 border border-gray-400 rounded-sm outline-none focus:border-primary-greenish focus:bg-white"
                  />
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="relative mb-4">
                <label for="message" class="text-sm leading-7 text-gray-600">
                  {{ $t("message") }}
                </label>
                <ValidationProvider
                  name="Message"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <textarea
                    id="message"
                    v-model="message"
                    name="message"
                    :placeholder="$t('message')"
                    class="w-full h-32 px-3 py-2 text-sm leading-6 text-gray-700 placeholder-gray-400 transition-colors duration-200 ease-in-out bg-white border border-gray-400 rounded-sm outline-none resize-none focus:border-primary-greenish focus:bg-white"
                  ></textarea>
                  <span class="text-sm text-red-600">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <button
                loading="true"
                type="submit"
                value="send"
                class="w-full px-6 py-3 text-white transition-colors duration-200 ease-in-out border-0 rounded-sm bg-atn-primary focus:outline-none hover:opacity-90"
              >
                {{ $t("send-message") }}
              </button>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import emailjs from "emailjs-com";
import { baseUrl } from "../Api";

export default {
  name: "Contact",
  data: () => ({
    successMessage: false,
    // items: [],
    valid: true,
    name: "",
    email: "",
    message: "",
    error: [],
    loading: false,
    snackbar: false,
    snackbar_message: "",
  }),
  // async mounted() {
  //   try {
  //     const response = await axios.get(`${baseUrl}/contacts`);
  //     this.items = response.data;
  //   } catch (error) {
  //     this.error = error;
  //   }
  // },
  methods: {
    showToast() {
      this.$toast.success("Message sent sucessfully");
    },
    async onSubmit(e) {
      const success = await this.$refs.form.validate();
      if (success) {
        try {
          emailjs.sendForm(
            "service_zlnw8io",
            "template_mtsg036",
            e.target,
            "user_pHlXRSBk1ewiAuGzuc7jE"
          );
          this.$nextTick(() => {
            this.$refs.form.reset();
            this.name = "";
            this.email = "";
            this.message = "";
            this.showToast();
          });
        } catch (error) {
          this.error = error;
        }
      } else {
        this.error = true;
      }
    },
  },
};
</script>

<style></style>
